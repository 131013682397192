@font-face {
	font-family: 'Avenir Next';
	font-style: normal;
	font-weight: 100;
	src:
		url('./fonts/AvenirNext-UltraLight.woff') format('woff2');
}

@font-face {
	font-family: 'Avenir Next';
	font-style: italic;
	font-weight: 100;
	src:
		url('./fonts/AvenirNext-UltraLightItalic.woff') format('woff');
}

@font-face {
	font-family: 'Avenir Next';
	font-style: normal;
	font-weight: 300;
	src:
		url('./fonts/AvenirNext-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Avenir Next';
	font-style: italic;
	font-weight: 300;
	src:
		url('./fonts/AvenirNext-Italic.woff') format('woff');
}

@font-face {
	font-family: 'Avenir Next';
	font-style: normal;
	font-weight: 400;
	src:
		url('./fonts/AvenirNext-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Avenir Next';
	font-style: italic;
	font-weight: 400;
	src:
		url('./fonts/AvenirNext-MediumItalic.woff') format('woff');
}

@font-face {
	font-family: 'Avenir Next';
	font-style: normal;
	font-weight: 600;
	src:
		url('./fonts/AvenirNext-DemiBold.woff') format('woff');
}

@font-face {
	font-family: 'Avenir Next';
	font-style: normal;
	font-weight: 700;
	src:
		url('./fonts/AvenirNext-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Avenir Next';
	font-style: italic;
	font-weight: 700;
	src:
		url('./fonts/AvenirNext-BoldItalic.woff') format('woff');
}

@font-face {
	font-family: 'Avenir Next';
	font-style: normal;
	font-weight: 900;
	src:
		url('./fonts/AvenirNext-Heavy.woff') format('woff');
}

@font-face {
	font-family: 'Avenir Next';
	font-style: italic;
	font-weight: 900;
	src:
		url('./fonts/AvenirNext-HeavyItalic.woff') format('woff');
}
